.container-cotizar {
  background-image: url("../../images/fondo-san-jorge.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100vw 60vh;
  height: 100vh;
}

.logo-san-jorge {
  width: 300px;
  height: 110px;
  margin: 50px 0px 50px 0px;
}

.text-cotiza {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  color: #1b2130;
  margin-bottom: 7px;
}

.text-ingresa {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #5c6b86;
  text-align: center;
}

.text-pasos {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: black;
  text-align: center;
  margin-top: 20px;
}

.btn-activo {
  width: 180px;
  height: 40px;
  background: #899aba;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  animation-duration: 1s;
}

.btn-hecho {
  width: 180px;
  height: 40px;
  background: #393839;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  animation-duration: 1s;
  text-transform: capitalize;
}

.btn-disabled {
  width: 180px;
  height: 40px;
  background: #fff;
  border-radius: 10px;
  color: #96989a;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
  box-shadow: 0px 2px 20px rgba(57, 56, 57, 0.1);
}

.btn-continuar-disabled {
  outline: none;
  border: none;
  width: 180px;
  height: 40px;
  background: #e2e2e2;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
}

.btn-continuar-disabled-tablet {
  width: 135px;
  height: 33px;
  background: #e2e2e2;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
  margin: 10px 0px 10px 0px;
}

.btn-continuar-activo {
  outline: none;
  border: none;
  width: 180px;
  height: 40px;
  background: #29a1dc;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
}

.btn-continuar-activo-tablet {
  width: 135px;
  height: 33px;
  background: #29a1dc;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  margin: 10px 0px 10px 0px;
}

.btn-wsp {
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #29a1dc;
  color: #fff;
  font-family: "Gotham";
  font-weight: 500;
  height: 50px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
}

.wsp-icon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.select-input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(57, 56, 57, 0.1);
  height: 250px;
  width: 180px;
  overflow: scroll;
  overflow-x: hidden;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.select-input::-webkit-scrollbar {
  -webkit-appearance: none;
}

.select-input::-webkit-scrollbar:vertical {
  width: 8px;
}

.select-input::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

.select-input::-webkit-scrollbar:horizontal {
  height: 10px;
}

.select-input::-webkit-scrollbar-thumb {
  background-color: #899aba;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.select-input::-webkit-scrollbar-track {
  border-radius: 10px;
}

.text-opciones {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #899aba;
  margin-top: 12px;
  cursor: pointer;
  animation-duration: 0.8s;
  width: 100%;
  text-transform: capitalize;
}

.text-opciones:hover {
  cursor: pointer;
  color: #238ee8;
}

.input-kilometros {
  width: 180px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(57, 56, 57, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-kilometros-tablet {
  width: 180px;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(57, 56, 57, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.input-kms-tablet {
  outline: none;
  width: 133px;
  height: 25px;
  border: 1px solid #96989a;
  border-radius: 10px;
  text-align: center;
  color: #000000;
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #393839;
  margin-top: 10px;
}

.kms-input {
  outline: none;
  width: 160px;
  height: 30px;
  border: 1px solid #96989a;
  border-radius: 10px;
  text-align: center;
  color: #000000;
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #393839;
}

.containerPrincipalSJ {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url("../../images/fondo-san-jorge.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100vw 60vh;
}

.logoSanJorge {
  width: 300px;
  height: 100px;
  margin-bottom: 5vh;
}

.containerModal {
  width: 500px;
  height: 174px;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(57, 56, 57, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation-duration: 1s;
}

.containerModal p {
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 160%;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}

.containerButtonsModal {
  margin-top: 20px;
}

.buttonNoSJ {
  width: 200px;
  height: 50px;
  border-radius: 8px;
  color: #29a1dc;
  border: solid 1px #29a1dc;
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  background-color: #ffffff;
  margin-right: 5px;
  cursor: pointer;
}
.btn-outlined {
  width: 200px;
  height: 50px;
  border-radius: 8px;
  color: #29a1dc;
  border: solid 1px #29a1dc;
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  background-color: #ffffff;
  margin-right: 5px;
  cursor: pointer;
}

.btn-outlined a {
  text-decoration: none;
  color: #29a1dc;
}
.buttonNoSJ a {
  text-decoration: none;
  color: #29a1dc;
}

.buttonModificarSJ {
  border: none;
  outline: none;
  width: 200px;
  height: 50px;
  background: #29a1dc;
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 5px;
}

.cardResumenSJ {
  width: 600px;
  height: 350px;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(57, 56, 57, 0.1);
  border-radius: 10px;
  animation-duration: 1s;
}

.titleInicial {
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin: 40px 40px 20px 40px;
}

.containerInspeccion {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.textInspeccion {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #393839;
  margin-left: 40px;
}

.containerFechaHorarioText {
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerFechaHorarioText div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
}

.containerLocation {
  display: flex;
  align-items: center;
  margin-left: 40px;
  margin-top: 10px;
}
.containerLocation img {
  width: 11px;
  height: 17px;
  margin-right: 5px;
}
.textVehiculo {
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 29px;
  color: #000000;
  margin: 0px 40px 20px 40px;
}

.containerMarcaModeloVersion {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.textModelo {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #393839;
}
.containerButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 40px 40px 40px;
}

.buttonEditarTurnoSJ {
  border: none;
  outline: none;
  /* width: 225px; */
  height: 60px;
  width: 100%;
  background: #29a1dc;
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
}

.buttonCancelarTurnoSJ {
  width: 225px;
  height: 50px;
  border-radius: 8px;
  color: #29a1dc;
  border: solid 1px #29a1dc;
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  background-color: #ffffff;
  cursor: pointer;
}

.buttonModificarSJ {
  border: none;
  outline: none;
  width: 200px;
  height: 50px;
  background: #29a1dc;
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 5px;
}
.buttonNoSJ {
  width: 200px;
  height: 50px;
  border-radius: 8px;
  color: #29a1dc;
  border: solid 1px #29a1dc;
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  background-color: #ffffff;
  margin-right: 5px;
  cursor: pointer;
}

.container-paso-arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.arrow-back {
  width: 7px;
  height: 11px;
  margin-left: 15px;
  cursor: pointer;
}

.text-steps {
  width: 100%;
  text-align: center;
  margin-right: 20px;
}

.containerModalCotizar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
}
.modal-stopper {
  border: none;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 682px;
  height: 375px;
  background-color: white;
  background-image: url("../../images/background-modal-stopper-desktop.png");
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.top-modal-stopper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.bottom-modal-stopper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title-modal-stopper {
  color: #29a1dc;
  text-align: center;
  font-family: "Gotham";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.description-modal-stopper {
  color: #393839;
  text-align: center;
  font-family: "Gotham";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.description-modal-stopper-mobile {
  display: none;
}

.footer-modal-stopper {
  color: #29a1dc;
  text-align: center;
  font-family: "Gotham";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}

.btn-back-to-home {
  border: none;
  width: 301px;
  height: 43px;
  border-radius: 10px;
  background: #29a1dc;
  color: #fff;
  text-align: center;
  font-family: "Gotham";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.title-modal-cotizar {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  margin-top: 10px;
}

.containerModalCotizar form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.input-datos {
  border: 1px solid #393839;
  border-radius: 8px;
  font-family: "Gotham";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  width: 90%;
  padding: 10px 20px;
  outline: none;
}

.input-datos::placeholder {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #96989a;
  width: 100%;
}

.label-input {
  width: 100%;
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.description-input {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin-top: 5px;
}

.btn-cotizar-activo {
  width: 99%;
  height: 50px;
  background: #29a1dc;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gotham";
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  border: none;
  margin-top: 20px;
  margin-bottom: 10px;
}
.btn-contained {
  width: 200px;
  height: 50px;
  background: #29a1dc;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gotham";
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  border: none;
  margin-right: 10px;
}

.text-error {
  color: red;
  font-family: "Gotham";
  font-weight: 500;
  font-size: 12px;
  margin-top: 5px;
  width: 100%;
  margin-left: 10px;
}

.text-footer {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #1b2130;
}

.text-footer-two {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #29a1dc;
}

.modalCarManual {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px 30px 40px 30px;
  border-radius: 10px;
}

.titleModalCarManual {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #899aba;
}

.labelModalManual {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1b2130;
}

.selectAñoModalManual {
  background: #ffffff;
  border: 2px solid #e1e7ef;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  padding: 10px;
  margin-top: 5px;
  outline: none;
}

.inputModalAño {
  width: 98%;
  border: 2px solid #e1e7ef;
  border-radius: 10px;
  height: 30px;
  margin-top: 5px;
  padding-left: 10px;
  outline: none;
}

.inputModalAño::placeholder {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1b2130;
}

.btnClose {
  border: 2px solid #29a1dc;
  border-radius: 8px;
  background-color: #fff;
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #29a1dc;
  height: 50px;
  width: 175px;
  cursor: pointer;
}
.btnCotizar {
  border: none;
  background: #29a1dc;
  border-radius: 8px;
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  height: 50px;
  width: 175px;
  cursor: pointer;
  margin-left: 5px;
}

.containerCore {
  margin-top: 30px;
  height: 50vh;
}

.containerBtnKmsLow {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

@media screen and (max-width: 630px) {
  .cardTurnosSj {
    width: 345px;
    margin-bottom: 10px;
  }

  .titleInicial {
    font-size: 22px;
    text-align: center;
  }

  .logoSanJorge {
    width: 180px;
    height: 67px;
  }

  .cardResumenSJ {
    width: 345px;
    height: 370px;
  }

  .containerFechaHorarioText {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .containerInspeccion {
    display: none;
  }

  .containerButtons {
    flex-direction: column;
  }

  .buttonEditarTurnoSJ {
    width: 270px;
  }
  .buttonCancelarTurnoSJ {
    width: 270px;
    margin-top: 5px;
  }
  .containerModal {
    width: 345px;
    height: 240px;
  }

  .containerModal p {
    margin-bottom: 0px;
  }

  .containerButtonsModal {
    display: flex;
    flex-direction: column;
  }
  .buttonModificarSJ {
    width: 300px;
    height: 50px;
    margin: 0px;
    margin-top: 10px;
  }
  .buttonNoSJ {
    width: 300px;
    height: 50px;
    margin: 0px;
  }
  .btn-contained {
    margin-right: 0px !important;
    margin-bottom: 10px;
  }
  .btn-outlined {
    margin-right: 0px !important;
  }
}

.select-input-ajustable {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(57, 56, 57, 0.1);
  width: 180px;
  overflow-y: hidden;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.select-input-ajustable p {
  margin-bottom: 10px;
}
.btn-modal-client {
  border: none;
  width: 222px;
  height: 43px;
  border-radius: 10px;
  background: #29a1dc;
  color: #fff;
  text-align: center;
  font-family: "Gotham";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.btn-continue-client {
  border: 2px solid #29a1dc;
  border-radius: 8px;
  background-color: #fff;
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #29a1dc;
  height: 43px;
  width: 222px;
  cursor: pointer;
  margin-right: 10px;
}

.text-footer-modal-client {
  color: #29a1dc;
  text-align: center;
  font-family: "Gotham";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
}

@media screen and (max-width: 900px) {
  .select-input {
    width: 160px;
    height: 250px;
  }
  .input-kilometros-tablet {
    width: 160px;
  }
  .logo-san-jorge {
    width: 180px;
    height: 65px;
  }
  .select-input-ajustable {
    width: 160px;
  }
  .modal-stopper {
    padding: 10px;
    width: 323px;
    height: 368px;
    background-image: url("../../images/background-modal-stopper-mobile.png");
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }

  .title-modal-stopper {
    font-size: 26px;
  }

  .description-modal-stopper {
    display: none;
  }

  .description-modal-stopper-mobile {
    display: flex;
    color: #393839;
    text-align: center;
    font-family: "Gotham";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .footer-modal-stopper {
    font-size: 18px;
  }
  .btn-modal-client {
    width: 100%;
    height: 43px;
    margin-bottom: 10px;
  }

  .btn-continue-client {
    height: 43px;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .text-cotiza {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .text-ingresa {
    font-size: 16px;
  }
  .select-input-ajustable {
    width: 160px;
  }
  .containerModalCotizar {
    width: 320px;
  }
  .input-datos {
    width: 85%;
  }
  .title-modal-cotizar {
    font-size: 20px;
  }
  .modalCarManual {
    width: 320px;
  }
  .inputModalAño {
    width: 95%;
  }
  .btnClose {
    width: 150px;
  }
  .btnCotizar {
    width: 150px;
  }

  .logo-san-jorge {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.radio-button-container {
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.radio-button-input .form-check-input {
  border: 2px solid #864bfa;
}

.radio-button-input :checked {
  border: 2px solid #864bfa;
  background-color: #864bfa;
  color: #180e31;
}

.radio-button-input :focus {
  border: 2px solid #864bfa;
  background-color: #864bfa;
  color: #180e31;
}

.form-check-label {
  font-size: 20px !important;
}

.radio-button-label {
  color: #180e31;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-check-input {
  width: 20px;
  height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.radio-button-title {
  color: #482481;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.radio-button-input {
  margin-left: 10px !important;
}
